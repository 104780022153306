var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"home-hero",staticStyle:{"max-height":"100vh"},attrs:{"fluid":"","fill-height":""}},[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1 min-width= 5000px",attrs:{"headers":_vm.groupHeaders,"items":_vm.Ateams,"expanded":_vm.expanded,"item-key":"name","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Group A")]),_c('v-spacer')],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_vm._v(" Roster: "+_vm._s(item.pilots)+" "),_c('br'),_vm._v(" Timezone: "+_vm._s(item.timezone)+" "),_c('br'),_vm._v(" Squadron Description: "+_vm._s(item.desc)+" ")])]}}])}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.groupHeaders,"items":_vm.Bteams,"expanded":_vm.expanded2,"item-key":"name","show-expand":""},on:{"update:expanded":function($event){_vm.expanded2=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Group B")]),_c('v-spacer')],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_vm._v(" Roster: "+_vm._s(item.pilots)+" Timezone: "+_vm._s(item.timezone)+" Squadron Description: "+_vm._s(item.desc)+" ")])]}}])}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.groupHeaders,"items":_vm.Cteams,"expanded":_vm.expanded3,"item-key":"name","show-expand":""},on:{"update:expanded":function($event){_vm.expanded3=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Group C")]),_c('v-spacer')],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_vm._v(" Roster: "+_vm._s(item.pilots)+" Timezone: "+_vm._s(item.timezone)+" Squadron Description: "+_vm._s(item.desc)+" ")])]}}])}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.groupHeaders,"items":_vm.Dteams,"expanded":_vm.expanded4,"item-key":"name","show-expand":""},on:{"update:expanded":function($event){_vm.expanded4=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Group C")]),_c('v-spacer')],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_vm._v(" Roster: "+_vm._s(item.pilots)+" Timezone: "+_vm._s(item.timezone)+" Squadron Description: "+_vm._s(item.desc)+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }