<template>
    <v-container fluid fill-height class="home-hero" style="max-height: 100vh;">
        <v-col>
        
        <v-data-table
    :headers="  groupHeaders"
    :items="Ateams"

     
    :expanded.sync="expanded"
    item-key="name"
    show-expand
    class="elevation-1 min-width= 5000px"
    
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Group A</v-toolbar-title>
        <v-spacer></v-spacer>
          
      </v-toolbar>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        Roster: {{ item.pilots }} <br>
        Timezone: {{item.timezone}} <br>
        Squadron Description: {{item.desc}}
      </td>
    </template>
  </v-data-table>

  
  <v-data-table
    :headers="  groupHeaders"
    :items="Bteams"
     
    :expanded.sync="expanded2"
    item-key="name"
    show-expand
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Group B</v-toolbar-title>
        <v-spacer></v-spacer>
          
      </v-toolbar>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        Roster: {{ item.pilots }}
        Timezone: {{item.timezone}}
        Squadron Description: {{item.desc}}
      </td>
    </template>
  </v-data-table>
  <v-data-table
    :headers="  groupHeaders"
    :items="Cteams"
     
    :expanded.sync="expanded3"
    item-key="name"
    show-expand
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Group C</v-toolbar-title>
        <v-spacer></v-spacer>
          
      </v-toolbar>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
          
        Roster: {{ item.pilots }}
        Timezone: {{item.timezone}}
        Squadron Description: {{item.desc}}
      </td>
    </template>
  </v-data-table>
   
  <v-data-table
    :headers="  groupHeaders"
    :items="Dteams"
     
    :expanded.sync="expanded4"
    item-key="name"
    show-expand
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Group C</v-toolbar-title>
        <v-spacer></v-spacer>
          
      </v-toolbar>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
          
        Roster: {{ item.pilots }}
        Timezone: {{item.timezone}}
        Squadron Description: {{item.desc}}
      </td>
    </template>
  </v-data-table>
   
 




        </v-col>
    </v-container>

</template>

<script>
// fetch('https://data.invisiontechnology.eu/create')
//   .then(response => response.json())
//   .then(data => console.log(data));
export default {
    
    name: 'groupTables',
     data () {
      return {
        expanded: [],
        expanded2: [],
        expanded3: [],
        expanded4: [],
        show1: false,
        show2: false,
        singleExpand: false,
          groupHeaders: [
          {
            text: 'Team',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          { text: 'Rank', value: 'rank' },
          { text: 'Match W-L-T', value: 'wlt' },
          { text: 'TB', value: 'tb' },
          { text: 'Set Wins', value: 'sWins' },
          { text: 'Set Ties', value: 'sTies' },
          { text: 'Pts', value: 'pts' },
          { text: '', value: 'data-table-expand' },
        ],
        // var TgroupA = ["51stPVO/100KIAP","=36=  - 36° Stormo Virtuale","♔ GC ♔  - Golden Crown","[107th] - 107th JAS","=[TMC]= - The Motley Crew"]
        // var TgroupB = ["№15 \"Battle Axes\" Squadron","inSky","[oTp] - OneTrickPony","[TaktLwG66]","[VTAF]"]
        // var TgroupC = ["E-TF - Eclipse Task Force","=CNF= - 中国节/Chinese Festival","IIAF","SPQR","=420= - 420th Blazers"]
        //var TgroupD = ["[PTF] - Phoenix Task Force","[Alamo] - Alamo Squadron","[NZA] - DCSNZA","[LD_E] - Leading Edge","Cobra Aggressors"]
        Ateams: [
          {
            name: "51stPVO/100thKIAP",
            rank: 1,
            wlt: "0-0-0",
            tb: 0,
            sWins: 0,
            sTies: 0,
            pts: 0,
            pilots: ["[100☭] Frostie","[51☭] Teknetinium","[100☭] Rich","[51☭] Roofies","[51☭] PVNK (K)","[51☭] Morri (P)","[100☭] Breakshot","[100☭] BlackPixxel","[100☭] Coxy","[100☭] holimoli","[100☭] Yambo","[100☭] Jack (P)","[51☭] Shamansky"],
            timezone: "UTC",
            desc: "On 1-Mar-2008 51 PVO Regiment was declared combat ready. It is one of the oldest squads in DCS with over a decade of complete domination in competition and community events.",
          },
          {
            name: "=36=  - 36° Stormo Virtuale",
            rank: 2,
            wlt: "0-0-0",
            tb: 0,
            sWins: 0,
            sTies: 0,
            pts: 0,
            pilots:  ["=36=Falcon","=36=Drigo","=36=Ghost","=36=Merlin","=36=Rabo","=36=Darihawk(R)","=36=Dankym","=36=Redshark(R)","=36=Eircog","=36=Warhawk(R)","=36=Mav","=36=Tournament","=36=Majo","=36=Firo","=36=Johnny","=36=Devil","=36=Falez(R)"],
            timezone: "UTC",
            desc: "",
          },
          {
            name: "♔ GC ♔  - Golden Crown",
            rank: 3,
            wlt: "0-0-0",
            tb: 0,
            sWins: 0,
            sTies: 0,

            pts: 0,
            pilots: [],
            timezone: "UTC",
            desc: "",
          },
          {
            name: "[107th] - 107th JAS",
            rank: 4,
            wlt: "0-0-0",
            tb: 0,
            sWins: 0,
            sTies: 0,

            pts: 0,
            pilots: ["[107th] Sirius","[107th] Teomo","[107th] Professor","[107th] SpeedDemon","[107th] Static","[107th] Puddles","[107th] Sheepy"],
            timezone: "Zulu +10, -4, +1",
            desc: "",
          },
          {
            name: "=[TMC]= - The Motley Crew",
            rank: 5,
            wlt: "0-0-0",
            tb: 0,
            sWins: 0,
            sTies: 0,
            pts: 0,
            pilots: ["=[TMC]= Anon","=[TMC]= Beanbag Ninja","=[TMC]= Bres","=[TMC]=Cipher","=[TMC]= DevDev","=[TMC]= Dobber","=[TMC]= Elkan","=[TMC]= HellDog","=[TMC]= Kingston","=[TMC]= Mercury","=[TMC]= Nish","=[TMC]= Seahorse","=[TMC]= Sean","=[TMC]= Senpai","=[TMC]= Tail","=[TMC]= Viper","=[TMC]= Zelfer"],
            timezone: "20:00z",
            desc: "",
          },
        ],
        Bteams: [
             {
            name: "№15 \"Battle Axes\" Squadron",
            rank: 1,
            wlt: "0-0-0",
            tb: 0,
            sWins: 0,
            sTies: 0,
            pts: 0,
            pilots: ["№15 | Verticle Charlie","№15 | Drona","№15 | Skinner","№15 | Cougar","№15 | Viper","№15 | Raavan","№15 | Gunny","№15 | Neoshot"],
            timezone: "18:00z",
            desc: "",
          },
           {
            name: "inSky",
            rank: 2,
            wlt: "0-0-0",
            tb: 0,
            sWins: 0,
            sTies: 0,
            pts: 0,
            pilots:  ["inSky_1155","inSky_1821","inSky_123","inSky_512","inSky_163","inSky_1978","inSky_1973","inSky_1980","inSky_622","inSky_1989","inSky_368"],
            timezone: "UTC",
            desc: "",
          },
           {
            name: "[oTp] - OneTrickPony",
            rank: 3,
            wlt: "0-0-0",
            tb: 0,
            sWins: 0,
            sTies: 0,
            pts: 0,
            pilots: ["[oTp]-mauuuz","[oTp]-FreakinGunner","[oTp]-CoMa","[oTp]-KEULE","[oTp]-Rudi","[oTp]-Dagger","[oTp]-Blackpanther","[oTp]-Kjell","[oTp]-Asphelite"],
            timezone: "17:00z-21:00z",
            desc: "",
          },
           {
            name: "[TaktLwG66]",
            rank: 4,
            wlt: "0-0-0",
            tb: 0,
            sWins: 0,
            sTies: 0,
            pts: 0,
            pilots: ["[TaktLwG66] Brainiac","[TaktLwG66] Boomer","[TaktLwG66] Forgety","[TaktLwG66] Godfather","[TaktLwG66] Jimbo","[TaktLwG66] NoGear","[TaktLwG66] DaVinci","[TaktLwG66] PeeJott","[TaktLwG66] RedButton"],
            timezone: "UTC +1",
            desc: "",
          },
           {
            name: "[VTAF]",
            rank: 5,
            wlt: "0-0-0",
            tb: 0,
            sWins: 0,
            sTies: 0,
            pts: 0,
            pilots: ["[VTAF] Archer","[VTAF] Grasshopper","[VTAF] Jak","[VTAF] Zachrix","[VTAF] Alpha","[VTAF] Mooncake","[VTAF] Vincent"],
            timezone: "18:00z-19:00z",
            desc: "",
          },
        ],
        Cteams: [
             {
            name: "E-TF - Eclipse Task Force",
            rank: 1,
            wlt: "0-0-0",
            tb: 0,
            sWins: 0,
            sTies: 0,
            pts: 0,
            pilots: ["E-TF[101] Breeze","E-TF[102] Riker","E-TF[103] n8dog","E-TF[104] Aspen","E-TF[107] Picard","E-TF[108] Dizzy","E-TF[113] DoorMouse","E-TF[802] Arthas","E-TF[805] EletroBull"],
            timezone: "UTC",
            desc: "",
          },
           {
            name: "=CNF= - 中国节/Chinese Festival",
            rank: 2,
            wlt: "0-0-0",
            tb: 0,
            sWins: 0,
            sTies: 0,
            pts: 0,
            pilots: ["=CNF=101国庆节/National Day","=CNF=307女生节/Girl's Festival","=CNF=405清明节/Tomb Sweeping","=CNF=501劳动节/International Labour Day","=CNF=505端午节/The Dragon Boat Day","=CNF=707七夕节/Chinese Valentine's Day","=CNF=801建军节/Army's Day","=CNF=128烟雨节/Misty rain Festival","=CNF=910教师节/Teachers' Day","=CNF=701建党节/CPC Founding Day"],
            timezone: "UTC",
            desc: "",
          },
           {
            name: "IIAF",
            rank: 3,
            wlt: "0-0-0",
            tb: 0,
            sWins: 0,
            sTies: 0,
            pts: 0,
            pilots: [],
            timezone: "UTC",
            desc: "",
          },
           {
            name: "SPQR",
            rank: 4,
            wlt: "0-0-0",
            tb: 0,
            sWins: 0,
            sTies: 0,
            pts: 0,
            pilots: ["SPQR | Praetorian","SPQR | AlphaGator","SPQR | TheGopnikTsar","SPQR | TheMooseMarauder","SPQR | Assassin","SPQR | Grug","SPQR | Balalaika","SPQR | DSplayer","SPQR | nicktune1219","SPQR | Huffaluffagus","SPQR | Raptor_Vision"],
            timezone: "UTC",
            desc: "",
          },
           {
            name: "=420= - 420th Blazers",
            rank: 5,
            wlt: "0-0-0",
            tb: 0,
            sWins: 0,
            sTies: 0,
            pts: 0,
            pilots: ["=420= BurntCornMuffin*","=420= Deadseed","=420= Prime","=420= Bear","=420= Xeno426","=420= Arbitrary*","=420= jzilla*","=420= Kingcrab","=420= Sneep","=420= Jae","=420= Vähäkylä*","=420= Snapshot","=420= wally*"],
            timezone: "UTC",
            desc: "",
          },
        ],
         Dteams: [
             {
            name: "[PTF] - Phoenix Task Force",
            rank: 1,
            wlt: "0-0-0",
            tb: 0,
            sWins: 0,
            sTies: 0,
            pts: 0,
            pilots: ["[PTF]Gattling","[PTF]Ghori","[PTF]Falcon","[PTF]Titan","[PTF]Kula","[PTF]Lucky","[PTF]RedLine","[PTF]Predator","[PTF]Sheikh","[PTF]Spark","[PTF]Zeus"],
            timezone: "18:00z",
            desc: "",
          },
           {
            name: "[Alamo] - Alamo Squadron",
            rank: 2,
            wlt: "0-0-0",
            tb: 0,
            sWins: 0,
            sTies: 0,
            pts: 0,
            pilots: ["[Alamo] Prez","[Alamo] Emperor","[Alamo] TrueMetroMan","[Alamo] JustMrak","[Alamo] Tiger","[Alamo] Darkthorian","[Alamo] nerd","[Alamo] Masterchief","[Alamo] Thompson","[Alamo] Jinx","[Alamo] Svenuu","[Alamo] Crusada","[Alamo] Sam"],
            timezone: "16:00z-20:00z",
            desc: "",
          },
           {
            name: "[NZA] - DCSNZA",
            rank: 3,
            wlt: "0-0-0",
            tb: 0,
            sWins: 0,
            sTies: 0,
            pts: 0,
            pilots: ["[NZA]Status32","[NZA]Ragnar_41","[NZA]CJM","[NZA]Shhags","[NZA]Phoenix","[NZA]Wipeout72","[NZA]Lamb","[NZA]Simonator","[NZA]Numbers","[NZA]Skunk_Mcfunk","[NZA]Strix","[NZA]Batman15","[NZA]Vulcan","[NZA]Dropkick"],
            timezone: "Zulu+11/13",
            desc: "",
          },
           {
            name: "[LD_E] - Leading Edge",
            rank: 4,
            wlt: "0-0-0",
            tb: 0,
            sWins: 0,
            sTies: 0,
            pts: 0,
            pilots: ["[LD_E] LaCrepe","[LD_E] Zoltar","[LD_E] BananaMayo","[LD_E] Pegase","[LD_E] Stingers","[LD_E] Tigrou","[LD_E] Winnie","[LD_E] Repied","[LD_E] WildKakahuette"],
            timezone: "UTC",
            desc: "",
          },
           {
            name: "Cobra Aggressors",
            rank: 5,
            wlt: "0-0-0",
            tb: 0,
            sWins: 0,
            sTies: 0,
            pts: 0,
            pilots:  ["GreenDotSpeed","SevenAlpha","Rick1perquin","rattinzke","Akgi","stock_88","aletrecio76","wendy","kro","sierrakillo","lindy"],
            timezone: "UTC",
            desc: "",
          },
        ]
      }
    },
    
};
</script>

<style scoped>

</style>
