<template>
<v-container fluid fill-height class="home-hero" style="max-height: 100vh;">
   
  <v-sheet
    class="mx-auto"
    elevation="8"
    max-width="1800"
    min-height="1060"
  >
  <div class="headline text-center mt-3 pt-16">Formatting of Timetable</div>
   <p class="subheading text-center mt-3">The timetable is broken down into several weekends. Each weekend is a frame here with the date being that of the starting Fri. The currently selected frame will turn blue and will also try to center on the screen. The image displays a brief view of the matches that weekend and cards showing each match, their time, day and streamer.</p>
    <v-slide-group
      v-model="model"
      class="pa-4"
      show-arrows
      center-active
      mandatory
    >
      <v-slide-item
        v-for="n in weeks"
        :key="n"
        v-slot="{ active, toggle }"
      >
        <v-card
          :color="active ? 'primary' : 'white'"
          class="ma-4"
          height="455"
          width="200"
          @click="toggle"
        >
        <!-- {{ active ? n.weekstart : n.weekstart }} -->
        <v-img
      v-bind:src= "n.weekImg"
      
      
    ></v-img>
        
          <v-row
            class="fill-height"
            align="center"
            justify="center"
          >
            <v-scale-transition>
              <v-icon
                v-if="active"
                color="white"
                size="48"
                v-text="'mdi-close-circle-outline'"
              ></v-icon>
            </v-scale-transition>
          </v-row>
        </v-card>
      </v-slide-item>
    </v-slide-group>

    <v-expand-transition>
      <v-sheet
        v-if="model != null"
        height="200"
        tile
      >
        <v-row
          class="fill-height"
          align="center"
          justify="center"
        >
          <v-card
    class="mx-auto"
    elevation="25"
  shaped
  outlined
  tile
    max-width="300" v-for="m in weeks[model].matches"
    min-width="100" max-height ="400" min-height ="50"
        :key="m"
  >
    <v-img
      v-bind:src= "m.img"
      
    ></v-img>

    <v-card-title>
      <u>Time: {{m.time}} on {{m.date}}</u> <br>
      {{m.team1}} vs {{m.team2}}
    </v-card-title>

    <v-card-subtitle>
      Time: {{m.time}} on {{m.date}} | Streamer Link: <a :href="m.twitch" target=”_blank” >{{m.twitch}}</a><br>
      Map: {{m.map}}    | Score: {{m.score}} 
    </v-card-subtitle>

    

      

    
  </v-card>

        </v-row>
      </v-sheet>
    </v-expand-transition>
  </v-sheet>
  </v-container>
</template>
<script>
  export default {
    data: () => ({
      model: null,
      weeks: [
          {
              weekstart : "7th January",
              weekImg: require("../assets/img/w1.png"),
              matches: [{
                  team1: "36° Stormo Virtuale",
                  team2: "The Motley Crew",
                  twitch: "",
                  map: "",
                  score: "",
                  time: "1800z",
                  date: "Fri 7th Jan",
                  img: require("../assets/img/36vsTMC.png")

              },
               {
                  team1: "Golden Crown",
                  team2: "107th JAS",
                  twitch: "TBD",
                  map: "TBD",
                  time: "1800z",
                  date: "Sat 8th Jan",
                  img: require("../assets/img/GCvs107.png")

              },
               {
                  team1: "inSky",
                  team2: "[VTAF]",
                  twitch: "TBD",
                  map: "TBD",
                  time: "1500z",
                  date: "Sat 8th Jan",
                  img: require("../assets/img/SkyvsVTAF.png")

              },
               {
                  team1: "中国节/Chinese Festival",
                  team2: "420th Blazers",
                  twitch: "TBD",
                  map: "TBD",
                  time: "1500z",
                  date: "Sun 9th Jan",
                  img: require("../assets/img/CFvs420.png")

              },
               {
                  team1: "Alamo Squadron",
                  team2: "Cobra Aggressors",
                  twitch: "TBD",
                  map: "TBD",
                  time: "1800z",
                  date: "Sun 9th Jan",
                  img: require("../assets/img/AlamovsCobra.png")

              },]
          },
          {
              weekstart : "14th January",
              weekImg: require("../assets/img/w2.png"),
              matches: [{
                  team1: "OneTrickPony",
                  team2: "[TaktLwG66]",
                  twitch: "TBD",
                  map: "TBD",
                  time: "1800z",
                  date: "Fri 14th Jan",
                  img: require("../assets/img/OTPvsTakt.png")

              },
              {
                  team1: "NZA - DCSNZA",
                  team2: "LD_E - Leading Edge",
                  twitch: "TBD",
                  map: "TBD",
                  time: "2000z",
                  date: "Sat 15th Jan",
                  img: require("../assets/img/NZAvsLDE.png")

              },
               
               {
                  team1: "[VTAF]",
                  team2: "[oTp] - OneTrickPony",
                  twitch: "TBD",
                  map: "TBD",
                  time: "1800z",
                  date: "Sat 15th Jan",
                  img: require("../assets/img/VTAFvsOTP.png")

              },
               {
                  team1: "IIAF",
                  team2: "SPQR",
                  twitch: "TBD",
                  map: "TBD",
                  time: "1800z",
                  date: "Sun 16th Jan",
                  img: require("../assets/img/IIAFvsSPQR.png")

              },
              {
                  team1: "The Motley Crew",
                  team2: "Golden Crown",
                  twitch: "TBD",
                  map: "TBD",
                  time: "1800z",
                  date: "Sun 16th Jan",
                  img: require("../assets/img/TMCvsGC.png")

              },
               ]
          },
          {
              weekstart : "21st January",
              weekImg: require("../assets/img/w3.png"),
              matches:[ {
                  team1: "420th Blazers",
                  team2: "IIAF",
                  twitch: "TBD",
                  map: "TBD",
                  time: "1800z",
                  date: "Fri 21st Jan",
                  img: require("../assets/img/420vsIIAF.png")

              },
               {
                  team1: "Cobra Aggressors",
                  team2: "NZA - DCSNZA",
                  twitch: "TBD",
                  map: "TBD",
                  time: "2000z",
                  date: "Sat 22nd Jan",
                  img: require("../assets/img/CobravsNZA.png")

              },
               {
                  team1: "№15 \"Battle Axes\" Squadron",
                  team2: "inSky",
                  twitch: "TBD",
                  map: "TBD",
                  time: "1500z",
                  date: "Sat 22nd Jan",
                  img: require("../assets/img/15vsSKY.png")

              },
               {
                  team1: "E-TF - Eclipse Task Force",
                  team2: "中国节/Chinese Festival",
                  twitch: "TBD",
                  map: "TBD",
                  time: "1500z",
                  date: "Sun 23rd Jan",
                  img: require("../assets/img/ETFvsCF.png")

              },
              {
                  team1: "51stPVO/100KIAP",
                  team2: "36° Stormo Virtuale",
                  twitch: "TBD",
                  map: "TBD",
                  time: "1800z",
                  date: "Sun 23rd Jan",
                  img: require("../assets/img/51vs36.png")

              },
               ]
          },
          {
              weekstart : "28th January",
              weekImg: require("../assets/img/w4.png"),
              matches:[ {
                  team1: "PTF - Phoenix Task Force",
                  team2: "Alamo Squadron",
                  twitch: "TBD",
                  map: "TBD",
                  time: "1800z",
                  date: "Fri 28th Jan",
                  img: require("../assets/img/PTFvsAlamo.png")

              },
               {
                  team1: "Golden Crown",
                  team2: "51stPVO/100KIAP",
                  twitch: "TBD",
                  map: "TBD",
                  time: "1800z",
                  date: "Sat 29th Jan",
                  img: require("../assets/img/GCvs51.png")

              },
              {
                  team1: "NZA - DCSNZA",
                  team2: "PTF - Phoenix Task Force",
                  twitch: "TBD",
                  map: "TBD",
                  time: "2000z",
                  date: "Sat 29th Jan",
                  img: require("../assets/img/NZAvsPTF.png")

              },
               
               {
                  team1: "IIAF",
                  team2: "E-TF - Eclipse Task Force",
                  twitch: "TBD",
                  map: "TBD",
                  time: "1800z",
                  date: "Sun 30th Jan",
                  img: require("../assets/img/IIAFvsETF.png")

              },
               
              {
                  team1: "[oTp] - OneTrickPony",
                  team2: "№15 \"Battle Axes\" Squadron",
                  twitch: "TBD",
                  map: "TBD",
                  time: "1800z",
                  date: "Sun 30th Jan",
                  img: require("../assets/img/OTPvs15.png")

              },]
          },
          {
              weekstart : "4th February",
              weekImg: require("../assets/img/w5.png"),
              matches: [{
                  team1: "107th JAS",
                  team2: "The Motley Crew",
                  twitch: "TBD",
                  map: "TBD",
                  time: "1800z",
                  date: "Fri 4th Feb",
                  img: require("../assets/img/107vsTMC.png")

              },
               {
                  team1: "51stPVO/100KIAP",
                  team2: "107th JAS",
                  twitch: "TBD",
                  map: "TBD",
                  time: "1800z",
                  date: "Sat 5th Feb",
                  img: require("../assets/img/51vs107.png")

              },
               {
                  team1: "[TaktLwG66]",
                  team2: "[VTAF]",
                  twitch: "TBD",
                  map: "TBD",
                  time: "1800z",
                  date: "Sat 5th Feb",
                  img: require("../assets/img/TaktvsVTAF.png")

              },
               {
                  team1: "SPQR",
                  team2: "420th Blazers",
                  twitch: "TBD",
                  map: "TBD",
                  time: "1800z",
                  date: "Sun 6th Feb",
                  img: require("../assets/img/SPQRvs420.png")

              },
               {
                  team1: "LD_E - Leading Edge",
                  team2: "Cobra Aggressors",
                  twitch: "TBD",
                  map: "TBD",
                  time: "1800z",
                  date: "Sun 6th Feb",
                  img: require("../assets/img/LDEvsCobra.png")

              },]
          },
          {
              weekstart : "11th February",
              weekImg: require("../assets/img/w6.png"),
              matches:[ {
                  team1: "№15 \"Battle Axes\" Squadron",
                  team2: "[TaktLwG66]",
                  twitch: "TBD",
                  map: "TBD",
                  time: "1800z",
                  date: "Fri 11th Feb",
                  img: require("../assets/img/15vsTakt.png")

              },
               {
                  team1: "36° Stormo Virtuale",
                  team2: "Golden Crown",
                  twitch: "TBD",
                  map: "TBD",
                  time: "1800z",
                  date: "Sat 12th Feb",
                  img: require("../assets/img/36vsGC.png")

              },
               {
                  team1: "inSky",
                  team2: "[oTp] - OneTrickPony",
                  twitch: "TBD",
                  map: "TBD",
                  time: "1500z",
                  date: "Sat 12th Feb",
                  img: require("../assets/img/SKYvsOTP.png")

              },
               {
                  team1: "中国节/Chinese Festival",
                  team2: "IIAF",
                  twitch: "TBD",
                  map: "TBD",
                  time: "1500z",
                  date: "Sun 13th Feb",
                  img: require("../assets/img/CFvsIIAF.png")

              },
               {
                  team1: "PTF - Phoenix Task Force",
                  team2: "LD_E - Leading Edge",
                  twitch: "TBD",
                  map: "TBD",
                  time: "1800z",
                  date: "Sun 13th Feb",
                  img: require("../assets/img/PTFvsLDE.png")

              },]
          },
          {
              weekstart : "18th February",
              weekImg: require("../assets/img/w7.png"),
              matches: [{
                  team1: "E-TF - Eclipse Task Force",
                  team2: "SPQR",
                  twitch: "TBD",
                  map: "TBD",
                  time: "1800z",
                  date: "Fri 18th Feb",
                  img: require("../assets/img/ETFvsSPQR.png")

              },
              {
                  team1: "Alamo Squadron",
                  team2: "NZA - DCSNZA",
                  twitch: "TBD",
                  map: "TBD",
                  time: "2000z",
                  date: "Sat 19th Feb",
                  img: require("../assets/img/AlamovsNZA.png")

              },
               
               {
                  team1: "[TaktLwG66]",
                  team2: "inSky",
                  twitch: "TBD",
                  map: "TBD",
                  time: "1500z",
                  date: "Sat 19th Feb",
                  img: require("../assets/img/TaktvsSKY.png")

              },
               {
                  team1: "SPQR",
                  team2: "中国节/Chinese Festival",
                  twitch: "TBD",
                  map: "TBD",
                  time: "1500z",
                  date: "Sun 20th Feb",
                  img: require("../assets/img/SPQRvsCF.png")
              },
               
              {
                  team1: "107th JAS",
                  team2: "36° Stormo Virtuale",
                  twitch: "TBD",
                  map: "TBD",
                  time: "1800z",
                  date: "Sun 20th Feb",
                  img: require("../assets/img/107vs36.png")

              },]
          },
          {
              weekstart : "25th February",
              weekImg: require("../assets/img/w8.png"),
              matches: [{
                  team1: "LD_E - Leading Edge",
                  team2: "Alamo Squadron",
                  twitch: "TBD",
                  map: "TBD",
                  time: "1800z",
                  date: "Fri 25th Feb",
                  img: require("../assets/img/LDEvsAlamo.png")

              },
               {
                  team1: "The Motley Crew",
                  team2: "51stPVO/100KIAP",
                  twitch: "TBD",
                  map: "TBD",
                  time: "1800z",
                  date: "Sat 26th Feb",
                  img: require("../assets/img/TMCvs51.png")

              },
               {
                  team1: "[VTAF]",
                  team2: "№15 \"Battle Axes\" Squadron",
                  twitch: "TBD",
                  map: "TBD",
                  time: "1800z",
                  date: "Sat 26th Feb",
                  img: require("../assets/img/VTAFvs15.png")

              },
               {
                  team1: "420th Blazers",
                  team2: "E-TF - Eclipse Task Force",
                  twitch: "TBD",
                  map: "TBD",
                  time: "1800z",
                  date: "Sun 27th Feb",
                  img: require("../assets/img/420vsETF.png")

              },
               {
                  team1: "Cobra Aggressors",
                  team2: "PTF - Phoenix Task Force",
                  twitch: "TBD",
                  map: "TBD",
                  time: "1800z",
                  date: "Sun 27th Feb",
                  img: require("../assets/img/CobravsPTF.png")

              },]
          },
          {
              weekstart : "5th March",
              weekImg: require("../assets/img/Form1.png"),
              matches: [{
                  team1: "",
                  team2: "",
                  twitch: "TBD",
                  map: "TBD",
                  time: "1800z",
date: ""

              },
               {
                  team1: "",
                  team2: "",
                  twitch: "TBD",
                  map: "TBD",
                  time: "1800z",
date: ""

              },
               {
                  team1: "",
                  team2: "",
                  twitch: "TBD",
                  map: "TBD",
                  time: "1800z",
date: ""

              },
               {
                  team1: "",
                  team2: "",
                  twitch: "TBD",
                  map: "TBD",
                  time: "1800z",
date: ""

              },]
          },
          {
              weekstart : "12th March",
             weekImg: require("../assets/img/Form2.png"),
              matches: [{
                  team1: "",
                  team2: "",
                  twitch: "TBD",
                  map: "TBD",
                  time: "1800z",
date: ""

              },
               {
                  team1: "",
                  team2: "",
                  twitch: "TBD",
                  map: "TBD",
                  time: "1800z",
date: ""

              },]
             
          },
          {
              weekstart : "19th March",
              weekImg: require("../assets/img/FlankerSun.png"),
               matches: [{
                  team1: "",
                  team2: "",
                  twitch: "TBD",
                  map: "TBD",
                  time: "1800z",
date: ""

              },
               {
                  team1: "",
                  team2: "",
                  twitch: "TBD",
                  map: "TBD",
                  time: "1800z",
date: ""

              },]
          },
      ]
    }),
  }
</script>