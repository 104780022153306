<template>
    <span>
        <satac-splash></satac-splash>
        <satac-details></satac-details> 
         <timetable></timetable>  
         
        <tables></tables>


        
    </span>
</template>

<script>
import SatacSplash from '@/components/satacSplash';
import Tables from '@/components/GroupsTable';
import Timetable from '@/components/Timetable';
import SatacDetails from '@/components/TSATACDetails';


export default {
    name: 'satac',
    components: {
        SatacSplash,
        Tables,
        SatacDetails,
        Timetable

    }
};
</script>